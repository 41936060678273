/**
 * Foglia utils
 */
import { useTheme } from '@mui/material/styles';
import { createMakeAndWithStyles } from 'tss-react';

// export the styling utilities
export const { makeStyles, withStyles } = createMakeAndWithStyles({
  useTheme,
});

// create the page title
export const title = title => title ? title + ' | gcbs' : 'gcbs';

// check if the user has a permission on the class
export const userCanClass = (permissionsList, perm) => Array.isArray(permissionsList) && permissionsList.indexOf(perm) > -1;

// check if a permission is in the list provided
export const can = (perm, permissionsList) => Array.isArray(permissionsList) && permissionsList.indexOf(perm) > -1;

// compare arrays
export const compareArrays = (a, b) => {
  if (a.length !== b.length) return false;
  const uniqueValues = new Set([...a, ...b]);
  for (const v of uniqueValues) {
    const aCount = a.filter(e => e === v).length;
    const bCount = b.filter(e => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
};

// retrieve the JWT payload without checks
export const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

// transform a camelCase string in kebab-case
export const kebabize = (str, sep = '_') => {
  return str.split('').map((letter, idx) => {
    return letter.toUpperCase() === letter
      ? `${idx !== 0 ? sep : ''}${letter.toLowerCase()}`
      : letter;
  }).join('');
};

// capitalize the first letter
export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

// create a range of dates between the two params
export const dateRange = (start, end) => {
  const a = [];

  for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
    a.push(new Date(d));
  }

  return a;
};

// check whether a string is a valid JSON
export const isValidJson = json => {
  try {
    JSON.parse(json);
    return true;
  } catch (e) {
    return false;
  }
};

// image resize configs
export const imageResizeConfig = {
  quality: 0.8,
  maxWidth: 2048,
  maxHeight: 2048,
  autoRotate: true,
};